<template>
  <div class="footer">
    <!-- 版心 -->
      <div class="nav">
        <!-- <div class="container">
          <h3>友情链接：</h3>
            <div class="link">
              <div class="link-item">
                <p v-for="(v,i) in item31" :key="i">
                  <router-link to>{{ v }}</router-link>
                </p>
              </div>
              <div class="link-item">
                <p v-for="(v,i) in item32" :key="i">
                  <router-link to>{{ v }}</router-link>
                </p>
              </div>
              <div class="link-item">
                <p v-for="(v,i) in item33" :key="i">
                  <router-link to>{{ v }}</router-link>
                </p>
              </div>
            </div>
        </div> -->
      </div>
      <ul class="container">
        <!-- item1 -->
        <li class="item1">
          <h3>
            <el-link :underline="false" href="/home/index">
            <img @click="goIndex()" src="@/assets/images/newImages/logo_标准@2x(1).png" alt="图片加载失败" />
            </el-link>
            <!-- <div class="text">
              <p class="chinese">阳光臻达公共服务平台</p>
              <p class="english">Sunshine Access Public Service Platform</p>
            </div> -->
          </h3>
        </li>
        <li>
          <p v-for="(v,i) in item1" :key="i">{{ v }}</p>
        </li>
        <!-- <div class="EBS">
          <img @click="goEBS" src="@/assets/images/电子招标标识.png" alt="" style="width: 80px;">
        </div> -->
        <!-- item2 -->
        <!-- <li class="item2">
          <h3>服务平台</h3>
          <p v-for="(v,i) in item2" :key="i">
            <router-link to>{{ v }}</router-link>
          </p>
        </li> -->

        <!-- item3 -->
        <!-- <li class="item3">
          <h3>友情链接</h3>
          <div class="link">
            <div class="link-item">
              <p v-for="(v,i) in item31" :key="i">
                <router-link to>{{ v }}</router-link>
              </p>
            </div>
            <div class="link-item">
              <p v-for="(v,i) in item32" :key="i">
                <router-link to>{{ v }}</router-link>
              </p>
            </div>
            <div class="link-item">
              <p v-for="(v,i) in item33" :key="i">
                <router-link to>{{ v }}</router-link>
              </p>
            </div>
          </div>
        </li> -->
      </ul>

    <!-- bottom -->
    <div class="bottom">
      <el-link class="bottom-link" :underline="false" href="https://beian.miit.gov.cn/#/Integrated/index">
        © 2022 阳光臻达电子招标投标交易平台版权所有  备案号：沪ICP备19017107号-7
      </el-link>
      <span class="bottom-link">&emsp;增值电信业务经营许可证：沪B2-20210687</span>
      <!-- <img src="@/assets/images/badge.png" alt />备案号：沪ICP备19017107号 -->
    </div>
    <div class="EBS">
      <img @click="goEBS" src="@/assets/images/电子招标标识.png" alt="">
    </div>
    </div>
</template>

<script>
export default {
  data() {
    return {
      item1: [
        "咨询热线：400-883-9300",
        "联系地址：云南省昆明市盘龙区低碳中心B-1201",
        "技术支持：上海思佩驰科技有限公司"
      ],
      item2: ["联系我们", "中心位置", "收藏本网", "设为首页"],
      item31: [
        "中国招标网",
        "云南省财政厅",
        "云南政府采购网",
        // "云南产权交易所"
      ],
      item32: [
        "中国招标网",
        "云南省财政厅",
        "云南政府采购网",
        // "云南产权交易所"
      ],
      item33: ["中国招标网", "云南省财政厅", "云南政府采购网", "云南产权交易所"]
    };
  },
  methods:{
    goEBS() {
      window.open('https://www.cnca.gov.cn/')
    },
    goIndex(){
      console.log(1);
      // this.$router.push({ name: '首页' })
    }
  }
};
</script>

<style lang="less" scoped>
.footer {
  // height: 400px;
  background: url('~@/assets/images/newImages/组 1810@2x.png') no-repeat center;
  background-size: 100% 343px;
  border-top: 6px solid #F36E31;
  // 版心
  .EBS {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 10px;
      img {
        cursor: pointer;
        width: 70px;
      }
    }
    .nav{
      border-bottom: 1px solid #777777;
      padding: 20px 0;
      .container{
        padding: 0 10px;
        display: flex;
        color: #ECECF1;
        position: relative;
        h3{
          font-size: 16px;
          color: #fff;
          margin-right: 10px;
        }
        .link{
          color: #fff;
          display: flex;
          .link-item{
            display: flex;
            font-size: 14px;
            color: #ECECF1;
            margin-right: 10px;
            >p{
              margin-right: 10px;
            }
          }
        }
      }
    }
    ul {
      display: flex;
      position: relative;
      .EBS {
        position: absolute;
        right: 0;
        display: flex;
        align-items: center;
        height: 100%;
        img {
          cursor: pointer;
        }
      }
      li {
        color: #ccc;
        line-height: 22px;
        font-size: 12px;
        padding:26px 0 ;
        h3 {
          color: #fff;
          font-size: 14px;
          line-height: 32px;
          margin-bottom: 16px;
        }
      }
      .item1 {
        margin-right: 20px;
        h3 {
          display: flex;
          align-items: center;
          img {
            height: 68px;
            margin-right: 10px;
            cursor: pointer;
          }
          .text {
            line-height: 160%;
            .chinese {
              font-size: 20px;
            }
            .english {
              font-size: 12px;
            }
          }
        }
      }
      .item3 {
        .link {
          display: flex;
          .link-item {
            margin-right: 20px;
          }
        }
      }
    }

  // bottom
  .bottom {
    .bottom-link{
      color: #ECECF1 ;
      font-size: 12px;
    }
    line-height: 70px;
    text-align: center;
    border-top: 1px solid #777777;
    img {
      width: 12px;
      margin-right: 8px;
    }
  }
}
</style>
