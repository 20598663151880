<template>
  <div class="header">
    <!-- 版心 -->
    <div class="container">
      <!-- left -->
      <div class="left">
        <!-- 首页的logo -->
        <!-- <img src="@/assets/images/logo.png" alt="图片加载失败" /> -->
        <el-link :underline="false" href="/home/index">
        <img src="@/assets/images/newImages/logo_标准@2x.png" alt="图片加载失败" class="logo" />

        </el-link>
        <!-- <div class="text">
          <p class="chinese">阳光臻达公共服务平台</p>
          <p class="english">Sunshine Access Public Service Platform</p>
        </div> -->
      </div>

      <!-- right -->
      <!-- 登录时的right -->
      <div class="right" v-if="$route.path.includes('login')">
        <img src="@/assets/images/返回箭头.png" alt="图片加载失败" />返回
      </div>
      <!-- 不是登录部分的right -->
      <!-- <div class="right" v-else>
        <el-input placeholder="请输入关键字" v-model="searchData" clearable>
          <template slot="append">
            <el-button icon="el-icon-search">搜索</el-button>
          </template>
        </el-input>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchData: ""
    };
  }
};
</script>

<style lang="less" scoped>
.header {
  .container {
    height: 90px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      display: flex;
      align-items: center;
      img {
        width: 40px;
        margin-right: 10px;
      }
      .logo{
        width: 202px;
      }
      .text {
        line-height: 160%;
        .chinese {
          font-size: 20px;
        }
        .english {
          font-size: 12px;
        }
      }
    }
    .right {
      color: #434343;
      font-size: 20px;
      height: 32px;
      cursor: pointer;
      /deep/ .el-input__inner{
        border-radius: 0;
        width: 260px;
        height: 32px;
      }
      /deep/.el-input.is-active .el-input__inner,/deep/ .el-input__inner:focus {
          border-color: #F36E31;
          outline: 0;
      }
      /deep/.el-input-group__append {
        width: 80px;
        height: 32px;
        color: white;
        background: #F36E31;
        border: none;
        border-radius: 0;
      }
      img {
        margin-right: 10px;
        width: 20px;
      }
    }
  }
}
</style>