<template>
  <div class="login">
    <!-- header -->
    <Header></Header>

    <!-- middle -->
    <div class="middle">
      <!-- main -->
      <div class="main">
        <!-- left -->
        <div class="left">
          <img src="../assets/images/login-building.png" alt="图片加载失败" />
        </div>
        <!-- right -->
        <div class="right">
          <!-- 局部更新的页面 -->
          <router-view></router-view>
        </div>
      </div>
    </div>

    <!-- footer -->
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "@/components/Layout/Footer.vue";
import Header from "@/components/Layout/Header.vue";

export default {
  components: {
    Footer,
    Header
  }
};
</script>

<style lang="less" scoped>
.login {
  .middle {
    height: 560px;
    background: url("~@/assets/images/login-bg.png");
    background-size: 100% 100%;
    display: flex;
    .main {
      display: flex;
      margin: auto; //父元素设置df，子元素设置margin: auto，就可以保证子元素居中了
      .left {
        img {
          width: 336px;
        }
      }
      .right {
        width: 354px;
        padding-top: 20px;
        background: #fff;
      }
    }
  }
}
</style>